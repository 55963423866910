html {
  font-family: system-ui, sans-serif;
  font-size: 1.25rem;
}

body {
  margin: 0;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.maps {
  padding: 0 3rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ismailcet {
  margin-left: 0.4rem;
  text-decoration: none;
  color: #000;
}
